import { Model } from "../models/model";
import { setTabTitle } from "../utils/set-tab-title";
import { ViewFactory } from "../factories/view.factory";
import { View } from "../view/view";

export class ButtonContainerService {
  private intervalTimer?: number;
  private defaultTitle = document.title;
  private view?: View;

  constructor(private model: Model, private viewFactory: ViewFactory) {}

  public init(renderingCompleteCallback: () => void): void {
    // Create view initializes iframe which opens the communication channel with fore to fetch the orgConfig
    const view = this.viewFactory.create(
      this.model.$chatIsVisible.value,
      () => this.onMainButtonClick(),
      () => this.onCloseIncommingMessagesButtonClick()
    );
    this.view = view;

    this.model.$chatIsVisible.on((chatIsVisible) =>
      view.setChatIsVisible(chatIsVisible)
    );

    this.model.$incomingMessages.on((incomingMessages) => {
      view.clearMessages();
      incomingMessages.forEach((incomingMessage) =>
        view.addMessage(
          incomingMessage.text,
          () => {
            this.model.$incomingMessages.value = [];
            this.model.$chatIsVisible.value = true;
          },
          incomingMessage.avatarFirstName
        )
      );
    });

    // To receive the orgConfig from fore, we have to initialize fore iframe with create view first
    this.model.$orgConfig.on((orgConfig) => {
      if (orgConfig) {
        view.render(orgConfig);
        renderingCompleteCallback();
      }
    });
  }

  public agentVideoExpanded(isExpanded: boolean): void {
    this.view?.setExpandedIframeWidth(isExpanded);
  }

  private onMainButtonClick(): void {
    this.model.$chatIsVisible.value = !this.model.$chatIsVisible.value;
  }

  private onCloseIncommingMessagesButtonClick(): void {
    this.model.$incomingMessages.value = [];
  }

  public setIsUnread(isUnread: boolean): void {
    this.intervalTimer = setTabTitle(
      this.model.language,
      isUnread,
      this.defaultTitle,
      this.intervalTimer
    );
  }

  public show(): void {
    this.view?.showMainButton();
  }

  public hide(): void {
    this.view?.hideMainButton();
  }
}
