import { FieldState, FormState } from "formstate";
import { action, computed, observable } from "mobx";
import { WorkingInterval } from "@fidget/common/utils/getWorkingHours";
import { setHours, setMinutes, startOfHour, startOfMinute } from "date-fns";
import { MINUTE_MILLIS, TimeUtils } from "@fidget/common/utils/timeUtils";

export class WorkingDay {
  name: string;
  interval: { from: FieldState<number>; to: FieldState<number> };
  intervalForm: FormState<{ from: FieldState<number>; to: FieldState<number> }>;
  @observable previousDayInterval: WorkingInterval;

  constructor(name: string, interval: WorkingInterval) {
    this.name = name;
    this.interval = {
      from: new FieldState(interval.from),
      to: new FieldState(interval.to)
    };
    const prevInterval = this.isDisabled ? this.defaultWorkingHours : interval;
    this.intervalForm = new FormState(this.interval);
    this.previousDayInterval = prevInterval;
  }

  @computed get defaultWorkingHours(): WorkingInterval {
    const from = startOfHour(setHours(new Date(this.fromAsDate), 8)).getTime();
    const to = startOfHour(setHours(new Date(this.fromAsDate), 16)).getTime();
    return { from, to };
  }

  @computed get isDisabled(): boolean {
    return this.interval.to.value === this.dayStart;
  }

  @computed get isDefault(): boolean {
    return (
      this.interval.from.value === this.defaultWorkingHours.from &&
      this.interval.to.value === this.defaultWorkingHours.to
    );
  }

  @computed get fromAsDate(): Date {
    return new Date(this.interval.from.value);
  }

  @computed get toAsDate(): Date {
    return new Date(this.interval.to.value);
  }

  @computed get dayStart(): number {
    return startOfHour(setHours(this.fromAsDate, 0)).getTime();
  }

  @computed get dayEnd(): number {
    return startOfMinute(setMinutes(setHours(this.fromAsDate, 23), 59)).getTime();
  }

  @computed get fromTimesOptions(): Date[] {
    return TimeUtils.dailyCalendarRange(
      this.dayStart,
      this.dayEnd,
      15 * MINUTE_MILLIS,
      false
    ).toArray();
  }

  @computed get toTimesOptions(): Date[] {
    return TimeUtils.dailyCalendarRange(
      this.dayStart,
      this.dayEnd,
      15 * MINUTE_MILLIS,
      true
    ).toArray();
  }

  @action.bound setPreviousDayInterval = (newPrevInterval: WorkingInterval) => {
    this.previousDayInterval = newPrevInterval;
  };

  setValues = (interval: WorkingInterval) => {
    this.interval.from.onChange(interval.from);
    this.interval.to.onChange(interval.to);
  };
}
