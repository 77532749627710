import { WindowWithScript } from "./models/window";
import { CloserApi } from "./closer-api";
import { logger } from "@fidget/common/utils";
import { getResidentVersion } from "./utils/version";

export class MainModule {
  constructor(private window: WindowWithScript) {}

  bootstrap(): void {
    const { closer } = this.window;

    logger.debug(`Closer: bootstrapping resident version ${getResidentVersion()}`);

    if (!closer) {
      throw new Error("Closer: closer object is not accessible in the window");
    }

    if (!closer.q) {
      throw new Error("Closer: closer.q queue is not accessible");
    }

    const closerApi = new CloserApi(closer.q || [], closer.scriptUrl);
    // Apply the real closer api instead of the closer loading script pasted by the client
    this.window.closer = closerApi;

    if (closer.q.length > 0) {
      const [maybeInitCall] = closer.q;
      // If first element of api calls queue is init - process it
      if (maybeInitCall?.method !== "init") {
        throw new Error(`Closer: init has to be run first instead of ${maybeInitCall?.method}`);
      }

      closerApi.init(maybeInitCall.args[0]);
    }
  }
}
