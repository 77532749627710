export const getRandomArrayItem = (array: readonly string[]): string | undefined => {
  if (array.length > 0) {
    return array[Math.floor(Math.random() * array.length)];
  }

  return undefined;
};

export const pushIfNotExists = <T extends unknown>(array: T[], el: T) => {
  return array.includes(el) ? array : [...array, el];
};

/** Acts as reduce calling given predicate on accumulator after each call, if predicate is true returns element & index */
export const findReduce =
  <E extends unknown, A extends unknown>(accPredicate: (acc: A) => boolean) =>
  (call: (acc: A, el: E, idx: number, array: E[]) => A, initial: A) =>
  (array: E[]): [E | undefined, number | undefined] => {
    let acc = initial;

    for (const [index, el] of array.entries()) {
      acc = call(acc, el, index, array);

      if (accPredicate(acc)) {
        return [el, index];
      }
    }

    return [undefined, undefined];
  };

export const rangeFromToPositive = (from: number, to: number) => {
  return Array.from({ length: to - from }, (_, k) => k + from);
};
