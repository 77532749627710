export const defaultOrgColor = document.documentElement.style.getPropertyValue("--mainOrgColor");

const parseHexToRgb = (color: string): string[] | undefined => {
  const toDecValue = ([f, s]: string[]) =>
    (parseInt(f!, 16) * 16 + parseInt(s ? s : f!, 16)).toString();

  // if #fff
  if (/^#[A-Fa-f0-9]{3}$/.test(color)) {
    return [[color[1]!], [color[2]!], [color[3]!]].map(toDecValue);
  }
  // if #ffffff
  else if (/^#[A-Fa-f0-9]{6}$/.test(color)) {
    return [
      [color[1]!, color[2]!],
      [color[3]!, color[4]!],
      [color[5]!, color[6]!]
    ].map(toDecValue!);
  }

  return undefined;
};

export const setOrgColor = (orgColor: string) => {
  document.documentElement.style.setProperty("--mainOrgColor", orgColor);
  setOrgColorRGB(orgColor);
};

export const setOrgColorRGB = (orgColor: string) => {
  const orgColorRGB = parseHexToRgb(orgColor);
  if (orgColorRGB) {
    document.documentElement.style.setProperty("--mainOrgColorRGB", orgColorRGB.join(", "));
  }
};
