export type WindowListenerType = "popstate" | "blur" | "resize" | "focus";
export type BodyListenerType = "mouseleave";

export class BrowserListenersService {
  private deinitCallbacks: ReadonlyArray<() => void> = [];

  constructor(private readonly window: Window) {}

  public registerWindowListener(type: WindowListenerType, callback: EventListenerOrEventListenerObject, options?: boolean) {
    this.window.addEventListener(type, callback, options);
    const deinitCallback = () => this.window.removeEventListener(type, callback);
    this.deinitCallbacks = [...this.deinitCallbacks, deinitCallback];
  }

  public registerBodyListener(type: BodyListenerType, callback: EventListenerOrEventListenerObject, options?: boolean) {
    this.window.document.body.addEventListener(type, callback, options);
    const deinitCallback = () => this.window.document.body.removeEventListener(type, callback);
    this.deinitCallbacks = [...this.deinitCallbacks, deinitCallback];
  }

  public clearAllListeners(): void {
    this.deinitCallbacks.forEach(cb => cb());
  }

}
