import { ApiConfig } from "@fidget/common/models/api-config";
import {
  ForeWindowQuery,
  openForeWindow,
} from "@fidget/common/services/windowUtils";
import { logger } from "@fidget/common/utils";
import { getCurrentScriptOrigin } from "@fidget/common/utils/current-script";
import { getOrigin } from "@fidget/common/utils/getOrigin";
import { parseQueryString } from "@fidget/common/utils/parseQueryString";
import { Model } from "../models/model";
import { Language } from "@fidget/common/models/language";
import { parseLanguage } from "@fidget/common/services/language";
import { BrowserListenersService } from "../services/browser-listeners.service";
import { ButtonContainerService } from "../services/button-container.service";
import { ForeWindowRequestsService } from "../services/fore-window-requests.service";
import { ResidentService } from "../services/resident.service";
import { createStorageService } from "./storage-service.factory";
import { TagsService } from "../services/tags/tags.service";
import { ViewTexts } from "../view/viewTexts";
import { StorageService } from "@fidget/common/services/storageService";
import {
  defaultPersistedState,
  PersistedState,
} from "../models/persisted-store";
import { ViewFactory } from "./view.factory";
import { ProactiveMessagesService } from "../services/proactive-messages/proactive-messages.service";

export class ResidentServiceFactory {
  constructor(
    private orgId: string,
    private apiConfig: ApiConfig,
    private widgetReadyCallback: () => void,
    private onWidgetInitialized: () => void,
    private deinitExternal: () => void,
    private scriptUrl?: string
  ) {}

  public async create(): Promise<ResidentService> {
    const baseUrl = this.apiConfig.baseUrl || this.getBaseUrl();
    const cssBaseUrl = this.getBaseUrl();

    const lang = this.determineLanguage();
    const storageService = createStorageService(this.orgId);

    const storedState = await this.getStoredState(storageService);

    const foreWindowQuery = this.getForeWindowQuery(baseUrl, lang);

    const foreWindow = openForeWindow(foreWindowQuery);
    const foreWindowRequests = new ForeWindowRequestsService(foreWindow);

    const isInitialChatVisible = this.isInitialChatVisible(
      storedState,
      foreWindowQuery
    );

    const showMainButton = this.determineShowMainButton(isInitialChatVisible);

    const model = new Model(this.orgId, lang, isInitialChatVisible);

    const tagsService = new TagsService(storageService, window.location);

    const proactiveMessagesService = new ProactiveMessagesService(
      model,
      tagsService,
      foreWindowRequests
    );

    const viewFactory = new ViewFactory(
      foreWindow,
      proactiveMessagesService,
      new ViewTexts(lang),
      showMainButton,
      this.apiConfig.container
    );

    const buttonContainerService = new ButtonContainerService(
      model,
      viewFactory
    );

    return new ResidentService(
      this.apiConfig,
      model,
      foreWindowRequests,
      new BrowserListenersService(window),
      tagsService,
      proactiveMessagesService,
      storageService,
      buttonContainerService,
      this.widgetReadyCallback,
      this.onWidgetInitialized,
      this.deinitExternal,
      cssBaseUrl
    );
  }

  private getForeWindowQuery(baseUrl: string, lang: Language): ForeWindowQuery {
    const query = parseQueryString();
    const {
      autoClientCalling,
      alwaysAutoCall,
      callingOnAssigneeChanged,
      botAgentName,
      callToNewAssigneeMessage,
      forceNewUser,
    } = this.apiConfig;

    return {
      baseUrl,
      orgId: this.orgId,
      lang,
      cc: query["cc"],
      cm: query["cm"],
      jm: query["jm"],
      acc: String(autoClientCalling),
      aac: String(alwaysAutoCall),
      cac: String(callingOnAssigneeChanged),
      ban: botAgentName,
      cnam: callToNewAssigneeMessage,
      fnu: String(forceNewUser),
    };
  }

  private getBaseUrl(): string {
    // try closer window api
    if (this.scriptUrl) {
      logger.debug("getBaseUrl: using window api");
      return getOrigin(this.scriptUrl);
    }
    // try current script src
    const currentScriptOrigin = getCurrentScriptOrigin();
    if (currentScriptOrigin) {
      logger.debug("getBaseUrl: using get current script origin");
      return currentScriptOrigin;
    }
    // fallback to window location
    logger.debug("getBaseUrl: falling back to window location origin");
    return window.location.origin;
  }

  private determineLanguage(): Language {
    const overriddenLanguage = parseLanguage(this.apiConfig.language);

    if (overriddenLanguage) {
      logger.debug(
        `Valid overriddenLanguage language was provided, using: ${overriddenLanguage}`
      );
      return overriddenLanguage;
    } else {
      const navigatorLanguage = parseLanguage(navigator.language.split("-")[0]);

      if (navigatorLanguage) {
        logger.debug(
          `Supported browser language detected, using: ${navigatorLanguage}`
        );
        return navigatorLanguage;
      } else {
        const defaultLanguage = Language.EN;
        logger.debug(
          `Unsupported browser language detected, using default: ${defaultLanguage}`
        );
        return defaultLanguage;
      }
    }
  }

  private isInitialChatVisible(
    persistedState: PersistedState,
    foreWindowQuery: ForeWindowQuery
  ): boolean {
    const {
      cc: continueConversationToken,
      cm: cancelMeetingToken,
      jm: joinMeetingToken,
    } = foreWindowQuery;

    const isChatVisibleOverride = !!(
      continueConversationToken ||
      joinMeetingToken ||
      cancelMeetingToken
    );

    return persistedState.chatIsVisible || isChatVisibleOverride;
  }

  private determineShowMainButton(isInitialChatVisible: boolean): boolean {
    if (isInitialChatVisible) {
      return isInitialChatVisible;
    }

    const configFlagValue = this.apiConfig.showButton;
    return configFlagValue === undefined || configFlagValue === null
      ? true
      : configFlagValue;
  }

  private async getStoredState(
    storageService: StorageService<PersistedState>
  ): Promise<PersistedState> {
    try {
      return storageService.getState();
    } catch (e) {
      logger.warn(
        `Could not read persisted state with error: ${e}, using default instead`
      );
      return defaultPersistedState;
    }
  }
}
