import { logger, parseQueryString } from "@fidget/common/utils";
import { injectCSS, makeQueryString } from "@fidget/common/utils";
import { WindowRequestUserDetails } from "@fidget/common/models/window-requests-user-details";
import { TagsService } from "./tags/tags.service";
import { CobrowseService } from "./cobrowse.service";
import { staticConfig } from "@fidget/common/config/static-config";
import { Config } from "@fidget/common/config/config";
import { ButtonContainerService } from "./button-container.service";
import { BrowserListenersService } from "./browser-listeners.service";
import { Model } from "../models/model";
import { ForeWindowRequestsService } from "./fore-window-requests.service";
import { getBreakingPoints } from "@fidget/common/models/breakingPoints";
import { StorageService } from "@fidget/common/services/storageService";
import { PersistedState } from "../models/persisted-store";
import { UserDetails } from "@fidget/common/models/user-details";
import { ApiConfig } from "@fidget/common/models/api-config";
import { getResidentVersion } from "../utils/version";
import { ProactiveMessagesService } from "./proactive-messages/proactive-messages.service";
import { Credentials } from "@fidget/common/models/credentials";

export class ResidentService {
  constructor(
    private apiConfig: ApiConfig,
    private model: Model,
    private foreWindowRequests: ForeWindowRequestsService,
    private browserListenersManager: BrowserListenersService,
    private tagsService: TagsService,
    private proactiveMessagesService: ProactiveMessagesService,
    private storageService: StorageService<PersistedState>,
    private buttonContainerService: ButtonContainerService,
    private foreInitializedCallback: () => void,
    private widgetInitializedCallback: () => void,
    private insideDeinitCallback: () => void,
    private cssBaseUrl: string
  ) {}

  public initializeWidgetButtonContainer(): void {
    // Inject css in async way to avoid fore load blocking
    this.injectWidgetCSS();
    this.setupWindowListeners();
    this.setupSdkCallbacks();
    this.registerForeWindowListeners();
    this.contextInitialize();
    this.buttonContainerService.init(() => this.onButtonRenderingComplete());
  }

  public contextInitialize(): void {
    const { apiKey, onOpen, onClose, onTagsChanged, getLoginHintToken, reinitParameters } = this.apiConfig;
    const oauthEnabled = getLoginHintToken !== undefined;

    this.model.$chatIsVisible.on(chatIsVisible => {
      this.storageService.setState({ chatIsVisible }); // save is open state for page refresh
      if (chatIsVisible) {
        this.model.$incomingMessages.value = [];
        this.buttonContainerService.show(); // show the button if widget has been opened
        if (onOpen) {
          onOpen();
        }
      } else {
        if (onClose) {
          onClose();
        }
      }
    });

    this.foreWindowRequests.onTagsChanged(onTagsChanged);

    this.foreWindowRequests.onGetAuthorizationParameters(() => Promise.resolve({ apiKey, oauthEnabled, reinitParameters }));
    this.foreWindowRequests.onMaximizeWidget(() => {
      this.openWidget();
    });

    this.foreWindowRequests.onGetLoginHintToken(() => {
      if (getLoginHintToken) {
        return getLoginHintToken();
      }
      return Promise.reject("Closer auth: No login hint token getter");
    });

    this.foreWindowRequests.onReloadNeeded(() => {
      const iframe = this.foreWindowRequests.iframe;
      if (iframe) {
        iframe.src += "";
      }
      return Promise.resolve();
    });

    this.foreWindowRequests.onHideWidget(() => {
      this.model.$chatIsVisible.value = false;
      return Promise.resolve();
    });

    this.foreWindowRequests.onIncomingMessage(text => {
      this.model.$incomingMessages.value = [...this.model.$incomingMessages.value, text];
      return Promise.resolve();
    });

    this.foreWindowRequests.onUnreadMessagesChanged(isUnread => {
      this.buttonContainerService.setIsUnread(isUnread);
      return Promise.resolve();
    });

    this.foreWindowRequests.onSetIframeWidth(agentVideoExpanded => {
      this.buttonContainerService.agentVideoExpanded(agentVideoExpanded);
      return Promise.resolve();
    });
  }

  public identifyUser(userDetails: UserDetails): Promise<void> {
    this.tagsService.updateIdentifyTags(userDetails);

    const userDetailsUpdatedTag: WindowRequestUserDetails = {
      ...userDetails,
      tags: this.tagsService.allMatchedTags
    };

    return this.foreWindowRequests.identifyUser(userDetailsUpdatedTag);
  }

  public forgetClient(): Promise<void> {
    return this.foreWindowRequests.forgetClientRequest();
  }

  public deinit(): Promise<void> {
    this.browserListenersManager.clearAllListeners();

    if (this.apiConfig.onDeinit) {
      this.apiConfig.onDeinit();
    }

    const iframe = this.foreWindowRequests.iframe;
    const mainContainer = document.getElementById(staticConfig.widgetContainerId);
    if (mainContainer !== null && iframe !== null) {
      return this.foreWindowRequests.forgetClientRequest().finally(() => {
        if (iframe) {
          iframe.src += "";
          iframe.remove();
        }
        mainContainer.remove();
        this.foreWindowRequests.foreWindow.unstash();
        this.foreWindowRequests.foreWindow.clearHandlers();
      });
    }

    return Promise.resolve();
  }

  public openWidget(arg?: string): void {
    logger.debug("widget opened from closer.open");
    this.model.$chatIsVisible.value = true;
    if (arg && arg === "autocall") {
      this.foreWindowRequests.openWidgetWithAutoCalling(true);
    }
  }

  private registerForeWindowListeners(): void {
    this.foreWindowRequests.onOrgConfig(orgConfig => {
      this.model.$orgConfig.value = orgConfig;
      return Promise.resolve();
    });

    this.foreWindowRequests.onDeinitCommand(() => this.handleDeinitCommand());
    this.foreWindowRequests.onForeInitialized(config => this.onForeInitialized(config));
    this.foreWindowRequests.onWidgetInitialized(isSuccess => this.onWidgetInitialized(isSuccess));
  }

  private async injectWidgetCSS(): Promise<void> {
    try {
      await injectCSS(this.cssBaseUrl + "/widget.css?" + makeQueryString({ ver: getResidentVersion() }));
    } catch (err) {
      logger.error("Closer resident: css loading error, won't render properly", err);
      throw err;
    }
  }

  /**
   * Fore is loaded, so we can proceed with fetching data via foreWindowRequests safely
   */
  private onForeInitialized(config: Config): void {
    this.foreInitializedCallback();
    this.initializeCobrowsing(config);
    this.setupForeWindowFetchers();
  }

  /**
   * This should be run when fore window is ready.
   * If fore is not ready, every send promise will fail.
   */
  private setupForeWindowFetchers(): void {
    this.fetchUrlTagsProactiveMessagesIntoModel();
    this.foreWindowRequests.sendUrlParams(parseQueryString(window.location.search.substring(1)));
    this.browserListenersManager.registerWindowListener("resize", () => this.notifyForeWindowResized());
    this.browserListenersManager.registerWindowListener("focus", () => this.tabActiveChanged(true));
    this.browserListenersManager.registerWindowListener("blur", () => this.tabActiveChanged(false));
  }

  private onWidgetInitialized(success: boolean): Promise<void> {
    logger.debug(`Widget (fore) initialized with status ${success}`);
    if (success) {
      const pickerWidgetMessages = this.apiConfig.pickerWidgetMessages;
      if (pickerWidgetMessages !== undefined) {
        this.foreWindowRequests.setProactiveMessages(pickerWidgetMessages);
      }
      this.foreWindowRequests.setSideBarMode(this.apiConfig.container ? true : false);
      this.notifyForeWindowResized();

      const currentPage = {
        url: window.location.href,
        title: document.title
      };
      this.foreWindowRequests.currentPageChanged(currentPage);
      setInterval(() => {
        if (currentPage.url !== window.location.href) {
          currentPage.url = window.location.href;
          currentPage.title = document.title;
          this.foreWindowRequests.currentPageChanged(currentPage);
        }
      }, 5000);

      this.widgetInitializedCallback();
    } else {
      logger.warn("Widget faild to authenticate");
    }
    return Promise.resolve();
  }

  private handleDeinitCommand(): Promise<void> {
    return new Promise(resolve => {
      this.deinit();
      this.insideDeinitCallback();
      return resolve();
    });
  }

  private onButtonRenderingComplete(): void {
    const { onLoad } = this.apiConfig;
    if (onLoad) {
      onLoad();
    }
    // currently it hides the preloader inside fore
    // it cannot be set earlier because the iframe window is not ready
    this.model.$chatIsVisible.on(chatIsVisible => {
      this.foreWindowRequests.chatVisibilityChanged(chatIsVisible);
      this.notifyForeWindowResized();
    });
  }

  private setupSdkCallbacks(): void {
    const { onMessageSent, onUserAuthorizationSuccess, onUserAuthorizationFailed } = this.apiConfig;

    if (onMessageSent) {
      this.foreWindowRequests.onOutcomingMessage(() => {
        onMessageSent();
        return Promise.resolve();
      });
    }

    if (onUserAuthorizationSuccess) {
      this.foreWindowRequests.onUserAuthorizationSuccess(authResult => {
        onUserAuthorizationSuccess(authResult);
        return Promise.resolve();
      });
    }

    if (onUserAuthorizationFailed) {
      this.foreWindowRequests.onUserAuthorizationFailed(() => {
        onUserAuthorizationFailed();
        return Promise.resolve();
      });
    }
  }

  private setupWindowListeners(): void {
    this.browserListenersManager.registerWindowListener("popstate", () => this.tagsService.notifyUrlChanged());
    this.browserListenersManager.registerWindowListener("blur", () => this.updateTagOnExitIntent());
    this.browserListenersManager.registerBodyListener("mouseleave", () => this.updateTagOnExitIntent());
  }

  private notifyForeWindowResized(): void {
    this.foreWindowRequests.windowResized({
      breakingPoints: getBreakingPoints(),
      browserWindowSize: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    });
  }

  private tabActiveChanged(tabIsActive: boolean): void {
    this.foreWindowRequests.tabActiveChanged(tabIsActive);
  }

  private updateTagOnExitIntent() {
    if (!this.model.$chatIsVisible.value) {
      this.tagsService.notifyExitIntent();
    }
  }

  private initializeCobrowsing(config: Config): void {
    if (config.services.cobrowseScriptEnabled) {
      const cobrowseService = new CobrowseService(config.services.cobrowseIOLicenseKey, () =>
        this.foreWindowRequests.clientEndedCobrowsing()
      );

      this.foreWindowRequests.onCobrowsingSessionInitialized(sessionId => cobrowseService.initializeSession(sessionId));

      this.foreWindowRequests.onCobrowsingSessionEnded(() => {
        cobrowseService.endSession();
        return Promise.resolve();
      });
    }
  }

  private fetchUrlTagsProactiveMessagesIntoModel() {
    this.foreWindowRequests.getUrlTagsProactiveMessages().then(tuple => {
      const { proactiveMessages, urlTagMappings } = tuple;

      this.tagsService.setUrlTagMappings(urlTagMappings);
      this.proactiveMessagesService.setProactiveMessages(proactiveMessages);
    });
  }

  public showButton(): void {
    this.buttonContainerService.show();
  }

  public hideButton(): void {
    this.buttonContainerService.hide();
  }

  public getCredentials(): Promise<Credentials> {
    return this.foreWindowRequests.getCredentials();
  }
}
