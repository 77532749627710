import { ApiConfig } from "@fidget/common/models/api-config";
import { UserDetails } from "@fidget/common/models/user-details";
import { Api, ApiCall } from "@fidget/common/models/widget-api";
import { logger } from "@fidget/common/utils";
import { ResidentServiceFactory } from "./factories/resident-service.factory";
import { ResidentService } from "./services/resident.service";

export class CloserApi implements Api {
  private residentServicePromise?: Promise<ResidentService>;
  private residentService?: ResidentService;

  private isWidgetInitialized = false;
  private isWidgetReady = false;

  constructor(public q: ApiCall[], public scriptUrl?: string) {}

  public async init(apiConfig: ApiConfig): Promise<void> {
    logger.debug("Closer init requested");
    const { orgId } = apiConfig;

    if (this.residentServicePromise) {
      throw new Error("Closer is already initialized, deinitialize first");
    }

    if (!orgId) {
      throw new Error("`orgId` configuration parameter is required");
    }

    const residentServiceFactory = new ResidentServiceFactory(
      orgId,
      apiConfig,
      () => this.onWidgetReady(),
      () => this.onWidgetInitialized(),
      () => this.deinitExternal(),
      this.scriptUrl
    );

    this.residentServicePromise = residentServiceFactory.create();

    try {
      this.residentService = await this.residentServicePromise;
      this.residentService.initializeWidgetButtonContainer();
    } catch (e) {
      logger.error(`Failed to initialize closer widget with error: ${e}`);
    }
  }

  public async reinit(config: ApiConfig): Promise<void> {
    const { orgId } = config;
    if (!orgId) {
      throw new Error("`orgId` configuration parameter is required");
    }

    const residentService = this.getResidentServiceSafely();
    const credentials = await residentService.getCredentials();
    logger.debug(`Closer reinit: reiniting from current user ${credentials.id}`);

    if (!credentials) {
      throw new Error("Closer reinit: Could not acquire current session before reinit");
    }

    await this.deinit();

    logger.debug("Closer: Starting reinit");

    await this.init({ ...config, reinitParameters: { enabled: true, credentials } });

    this.openWidget();
  }

  public identify(userDetails?: UserDetails): void {
    if (!userDetails) {
      throw new Error("Closer identify requires argument");
    }

    if (!this.residentServicePromise) {
      throw new Error("Closer identify: initialize the widget first");
    }

    if (this.isWidgetInitialized && this.residentService) {
      this.residentService.identifyUser(userDetails);
    } else {
      this.q = [...this.q, { method: "identify", args: [userDetails] }];
    }
  }

  public openWidget(arg?: string): void {
    if (!this.residentServicePromise) {
      throw new Error("Closer openWidget: initialize the widget first");
    }

    if (this.isWidgetReady && this.residentService) {
      this.residentService.openWidget(arg);
    } else {
      this.q = [...this.q, { method: "openWidget", args: [arg] }];
    }
  }

  public forgetClient(): void {
    this.getResidentServiceSafely().forgetClient();
  }

  public deinit(): Promise<void> {
    logger.debug("Closer deinit requested");
    return this.getResidentServiceSafely()
      .deinit()
      .finally(() => this.deinitExternal());
  }

  public showButton(): void {
    this.getResidentServiceSafely().showButton();
  }

  public hideButton(): void {
    this.getResidentServiceSafely().hideButton();
  }

  public getResidentServiceSafely(): ResidentService {
    if (!this.residentServicePromise) {
      throw new Error("Closer: initialize the widget first");
    }

    if (!this.residentService) {
      throw new Error("Closer: widget is not yet initialized");
    }

    return this.residentService;
  }

  private deinitExternal() {
    this.residentServicePromise = undefined;
    this.isWidgetInitialized = false;
    this.isWidgetReady = false;
  }

  private onWidgetReady(): void {
    this.isWidgetReady = true;
    this.processOpenWidgetQueue();
  }

  private onWidgetInitialized(): void {
    this.isWidgetInitialized = true;
    this.processIdentifyQueue();
  }

  private processOpenWidgetQueue(): void {
    return this.q
      .filter(apiCall => apiCall.method === "openWidget")
      .map(identify => identify.args[0])
      .forEach(identifyArg => this.openWidget(identifyArg));
  }

  private processIdentifyQueue(): void {
    return this.q
      .filter(apiCall => apiCall.method === "identify")
      .map(identify => identify.args[0])
      .forEach(identifyArg => this.identify(identifyArg));
  }
}
