const authConfig = {
  apiKeyHeader: "X-Api-Key",
  fingerprintHeader: "X-Fingerprint"
};

const uiConfig = {
  inboxDefaultPlaceholderNumber: 3,
  inboxYoursPlaceholderNumber: 2,
  notesHiddenSize: 3,
  calendar: {
    scrollMin: 0,
    scrollStep: 500,
    scrollTimerInterval: 200,
    scrollTopOffset: 150,
    scrollBottomOffset: 50,
    meetingTitleMaxLength: 20
  },
  messageWidgetTextInputMaxRows: 3,
  widgetPageSize: 20 // 2-3 screens
};

const appLimitsConfig = {
  roleNameMaxCharacters: 50,
  mainNotificationMaxCharacters: 500,
  agentGroupNameMaxCharacters: 35,
  tagNameMaxCharacters: 35,
  tagGroupNameMaxCharacters: 35,
  statusSlugMaxCharacters: 35,
  proactiveMessageMaxCharacters: 100,
  eventActionsMessageMaxCharacters: 500,
  chatMaxMessageLengthCharacters: 1000,
  aiIntentNameMaxCharacters: 140,

  tagGroupsMaxNumber: 128,
  tagsMaxNumber: 256,

  maxMessageAllowedCharactersForAgent: 1000,
  maxMessageAllowedCharactersForAgentUpperLimit: 2000,
  maxMessageAllowedCharactersForClient: 1000,
  maxMessageAllowedCharactersForClientUpperLimit: 2000,
  maxPictureFileSizeBytes: 5000000
};

const timesConfig = {
  autoCallingFailedTimeout: 3000,
  artichokeAskTimeout: 60000,
  disconnectNotificationDebounce: 1000,
  avgResponseReloadIntervalInMillis: 60000,
  aiModelQueryIntervalMillis: 3000,
  chatTypingThrottleMillis: 1000,
  chatTypingTimeoutMillis: 2000,
  resizeBrowserDebounceMillis: 250,
  guestProfilePatchDebounceMillis: 250,
  guestProfilePatchMaxDebounceMillis: 1000,
  timeoutSettingsDeleteConfirmationMs: 3000,
  inputDebounceMs: 300
};

const snoozeDefaultConstraints = {
  min: { minutes: 1 },
  max: { hours: 24 }
};
const closeDefaultConstraints = {
  min: { minutes: 1 },
  max: { hours: 24 }
};

const templateConfig = {
  rawTag: { opening: "<%= ", closing: " %>" },
  compiledTag: { opening: "#", closing: "" },
  templates: ["client.firstName", "agent.firstName"]
};

const chatCommandConsoleConfig = {
  commandActivationSequence: "/",
  mentionActivationSequence: "#",
  mentionDelimeterSequence: " "
};

export const staticConfig = {
  widgetContainerId: "closer-widget",
  localStorageKeyPrefix: "closer",
  closerSupportEmail: "support@closer.app",
  newestAccelerate: "accelerateUSD200820",
  launchPlan: "launch",
  beforeBillingPlan: "before_billing",
  orangeOrgId: "0534c24d-4474-4987-ba72-455d57752fc8",
  avgResponseHoursRange: 12,
  conversationsSlaDefaultRangeLowerBound: 10,
  conversationsSlaDefaultRangeUpperBound: 30,
  responseTimeDefaultRangeLowerBound: 1,
  responseTimeDefaultRangeUpperBound: 5,
  aiProbabilityHighLevel: 0.8,
  aiProbabilityMidLevel: 0.5,
  aiMinNonEmptyIntentsToTrain: 2,
  aiTestHistoryLimit: 10,
  backwardYearsAutoCompleteNumber: 30,
  aheadYearsAutoCompleteNumber: 1,
  yearsAheadValidityPickerUpperRange: 20,
  yearsBackwardValidityPickerUpperRange: 0,
  cobrowseIOconnectUrl:
    "https://cobrowse.io/connect?end_action=none&allow_popout=false&filter_device_id=",
  userflowDivSelector: "#userflow-ui",
  snoozeDefaultConstraints,
  closeDefaultConstraints,
  oidcLoginUrlOrgIdParamName: "orgId",
  autoAssignFormInputName: "tagGroupId",
  metabaseUserIdsAllQuery: "all",
  auth: authConfig,
  ...uiConfig,
  ...appLimitsConfig,
  ...timesConfig,
  ...templateConfig,
  ...chatCommandConsoleConfig
};

export type StaticConfig = typeof staticConfig;
