import {
  addDays,
  differenceInHours,
  getHours,
  getMinutes,
  setDay,
  setHours,
  setMinutes,
  startOfDay
} from "date-fns";
import { Range, Seq } from "immutable";
import { intl } from "@fidget/common/i18n/TypedIntl";

export const SECOND_MILLIS = 1000;
export const MINUTE_MILLIS = 60 * SECOND_MILLIS;
export const MINUTE_SECONDS = 60;
export const HOUR_MILLIS = 60 * MINUTE_MILLIS;
export const DAY_MILLIS = 24 * HOUR_MILLIS;

interface IMeeting {
  start: number;
  duration: number;
}

export class TimeUtils {
  public static dailyCalendarRange(
    startTimestamp: number,
    stopTimestamp: number,
    interval: number,
    withStopTimestamp?: boolean
  ): Seq.Indexed<Date> {
    const range = Range(0)
      .map((i) => new Date(startTimestamp + interval * i))
      .takeWhile((m) => m.getTime() < stopTimestamp);
    return withStopTimestamp ? range.concat([new Date(stopTimestamp)]) : range;
  }

  public static infiniteCalendarRange(startingPoint: Date, interval: number): Seq.Indexed<Date> {
    const startDate = new Date(startingPoint.getTime());
    startDate.setHours(0, 0, 0, 0);
    const stopDate = new Date(startDate.getTime());
    stopDate.setHours(24);
    return Range(0)
      .flatMap((day) => {
        const diff = day * DAY_MILLIS;
        return TimeUtils.dailyCalendarRange(
          startDate.getTime() + diff,
          stopDate.getTime() + diff,
          interval
        );
      })
      .skipWhile((m) => m.getTime() < startingPoint.getTime());
  }

  public static overlapsWithMeeting(meetings: IMeeting[], start: Date, duration: number): boolean {
    return meetings.some(
      (meeting) =>
        meeting.start < start.getTime() + duration &&
        start.getTime() < meeting.start + meeting.duration
    );
  }

  public static firstAvailableSlot(meetings: IMeeting[], interval: number): Date {
    return TimeUtils.infiniteCalendarRange(new Date(), interval)
      .skipWhile((m) => TimeUtils.overlapsWithMeeting(meetings, m, interval))
      .first()!;
  }

  public static lastMonday(date: Date): Date {
    return setDay(startOfDay(date), 1, { weekStartsOn: 1 });
  }

  public static formatDatesRange(dates: Seq.Indexed<Date>) {
    const firstDay = dates.first();
    const lastDay = dates.last();
    let result = firstDay && intl.formatDate(firstDay, { day: "numeric", month: "long" });
    if (lastDay && firstDay !== lastDay) {
      result += " - " + intl.formatDate(lastDay, { day: "numeric", month: "long" });
    }
    return result;
  }

  public static formatSimpleDuration(duration: number) {
    const hours = differenceInHours(duration, 0);
    const minutes = getMinutes(duration);
    const hoursString = hours > 0 ? `${hours}h` : "";
    const minutesString = minutes > 0 ? `${minutes}m` : "";
    return hoursString + minutesString;
  }

  public static datesRange(date: Date, weekly: boolean) {
    return weekly
      ? Range(0, 7).map((i) => addDays(TimeUtils.lastMonday(date), i))
      : Seq.Indexed.of(startOfDay(date));
  }

  public static copyHoursAndMinutes(from: Date, to: Date): Date {
    return setMinutes(setHours(to, getHours(from)), getMinutes(from));
  }
}
